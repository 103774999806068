import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import LP from '../pages/LearningPlan';

const LPN = () => {
    return (
        <div>
            <Nav />
            <LP />
            <Footer />
            
        </div>
    )
}

export default LPN