import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./routes/main";
import Resume from "./routes/Resu";
import KEI from "./routes/keigo";
import MAIL from "./routes/mail";
import Com from "./routes/com";
import Sef from "./routes/sef";
import More from "./routes/more";
import Cof from "./routes/cof";
import TRL from "./routes/Tran";
import DES from "./routes/Des";
import LPNN from "./routes/LeP";
import Fast from "./routes/Fast";
import Best from "./routes/Best";
import Tutor from "./routes/Tu";
import INV from "./routes/Inv";
import Intro from "./routes/intr";
import RESA from './routes/RResu';
import Terv from './routes/Inte';
import { LanguageProvider } from "./component/LanguageContext";


function App() {
  return (
    <>
      <LanguageProvider>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Gptshiboriyu" element={<Resume />} />
          <Route path="/GptResume" element={<RESA />} />
          <Route path="/GptCompany" element={<Com />} />
          <Route path="/Gptselfcheck" element={<Sef />} />
          <Route path="/Keigo" element={<KEI />} />
          <Route path="/Mail" element={<MAIL />} />
          <Route path="/Translate" element={<TRL />} />
          <Route path="/advise" element={<More />} />
          <Route path="/coffee" element={<Cof />} />
          <Route path="/Decision" element={<DES />} />
          <Route path="/LP" element={<LPNN />} />
          <Route path="/FastLearning" element={<Fast />} />
          <Route path="/LearnFromBest" element={<Best />} />
          <Route path="/LearnTutor" element={<Tutor />} />
          <Route path="/Involve" element={<INV />} />
          <Route path="/introduction" element={<Intro />} />
          <Route path="/GptInterview" element={<Terv />} />
        </Routes>
      </LanguageProvider>
    </>
  );
}

export default App;
