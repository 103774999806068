import { Button } from "antd";
import "../component/Automail.css";
import React, {useContext, useMemo } from "react";
import { LanguageContext } from "../component/LanguageContext";

function App() {
  const { language: Language } = useContext(LanguageContext);
    const text = useMemo(
      () => ({
        EN: {
          title: "buy a coffee",
          message: "If you find it useful, you can appreciate a cup of coffee",
          message1: "also follow my account and discord channel",
        },

        JP: {
          title: "コーヒー",
          message: "使えやすいと思ったらコーヒーください",
          message1:
            "個人垢とdiscordチャンネルをフォローすることもできます",
        },

        CN: {
          title: "买一杯咖啡",
          message: "如果你觉得好用,可以赞赏一杯咖啡",
          message1: "也可以关注我的账号和discord频道",
        },
      }),
      []
    );


  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>
      <h2>{text[Language].message1}</h2>
      <img
        src={process.env.PUBLIC_URL + "/pays.png"}
        alt="赞赏二维码"
        style={{ width: "200px", height: "auto" }}
      />
      <div>
        <a
          href="https://www.patreon.com/GPTWORK/membership"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="primary">appreciate</Button>
        </a>
      </div>
    </div>
  );
}

export default App;
