import React from 'react';
import './Footer.css'


const Footer = () => {
  return (
    <div className='footer'>
      Powered By Purumomo
    </div>
  );
};



export default Footer;
