import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Automail1 from '../pages/Automail1.jsx'

const MAIL = () => {
    return (
        <div>
            <Nav />
            <Automail1/> 
            <Footer />
            
        </div>
    )
}

export default MAIL