import React, { useState, useContext, useMemo } from "react";
import { Button } from "antd";
import "../component/Automail.css";
import { LanguageContext } from "../component/LanguageContext";

function App() {
  const [inputValue, setInputValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { language: Language } = useContext(LanguageContext);

    const text = useMemo(
      () => ({
        EN: {
          title: "Suggestions",
          message: "Write down your requirements",
          message1: "I will try to achieve",
        },

        JP: {
          title: "アドバイス",
          message: "貴重な意見を書いてください",
          message1: "できるだけ実現します",
        },

        CN: {
          title: "提建议",
          message: "写下您宝贵的建议和其他功能上的需求",
          message1: "我会尽可能实现",
        },
      }),
      []
    );
  const handleSubmit = async () => {
    if (!inputValue.trim()) {
      window.alert("请输入您的宝贵建议");
      return; // 如果当前没有输入文本，直接返回，不执行后面的代码
    }
    const webhookURL =
      "https://discord.com/api/webhooks/1099609466661523507/TVCGVzrOJZCika7N1ws50OemyScjZnZ7WB6EDQaJr31fbdylXqnX-uXXzs0gC53tlfvj";

    // 构造 HTTP 请求的 body 数据
    const requestBody = {
      content: inputValue,
    };

    try {
      setSubmitting(true);
      const response = await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        console.log("Sent message to Discord!");
        setInputValue(""); // 清空输入框
        window.alert("感谢你的支持！");
      } else {
        console.error("Failed to send message to Discord.");
        window.alert("发送失败 你可能需要魔法！");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>
      <h2>{text[Language].message1}</h2>
      <div className="Container p5">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{
            width: "60%",
            height: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "10px",
            outline: "none",
            fontSize: "14.56px",
          }}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={handleSubmit}
          disabled={submitting}
        >
          {submitting ? "SUBMITTING" : "SUBMIT"}
        </Button>
      </div>
    </div>
  );
}

export default App;
