import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Spin, Switch } from "antd";
import "../component/Automail.css";
import CopyButton from "../component/CopyButton";
import { LanguageContext } from "../component/LanguageContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

function App() {
  const { language: Language } = useContext(LanguageContext);
  const [gptmodel, setGptmodel] = useState("gpt-3.5-turbo-0613");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [show1, setshow1] = useState(false);
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const [inputvalue, setInputValue] = useState("天气真好我们去散步吧");

  const text = useMemo(
    () => ({
      EN: {
        title: "honorific",
        message: "input text with any languages",
        input: "Lets take a walk outside",
        beta: "Click to try GPTs beta version",
      },

      JP: {
        title: "敬語生成",
        message: "任意の言語で書いてください",
        input: "天気がいいから散歩しましょう",
        beta: "クリックして GPTs beta版を試す",
      },

      CN: {
        title: "敬语转换",
        message: "输入想转换成敬语的文本",
        input: "天气真好我们去散步吧",
        beta: "点击尝试GPTs测试版本",
      },
    }),
    []
  );

  useEffect(() => {
    setInputValue(text[Language].input);
  }, [Language, text]);
  
  const model = {
    gpt3: "gpt-3.5-turbo-0613",
    gpt4: "gpt-4-0613",
  };

  const handleSend = async (message) => {
    if (message === "要件を選びください") {
      return;
    }
    const newMessage = {
      message:
        "この文章を日本語の尊敬語と丁寧語を出力してください:" +
        inputvalue +
        "出力は、尊敬語：... ,丁寧語:...",
      direction: "outgoing",
      sender: "user",
    };

    setIsTyping(true);
    await processMessageToChatGPT([...messages, newMessage], () => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(false);
    });
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = [];
    for (const messageObject of chatMessages) {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      apiMessages.push({ role, content: messageObject.message });
    }

    const apiRequestBody = {
      model: gptmodel,
      messages: [...apiMessages],
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/getCompletion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiRequestBody),
      }
    );

    const data = await response.json();
    const messageText = data.choices[0].message.content;
    const messageChars = messageText.split("");
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setMessages([
        {
          message: messageText.slice(0, currentIndex + 1),
          sender: "ChatGPT",
        },
      ]);
      currentIndex++;
      if (currentIndex === messageChars.length) {
        clearInterval(intervalId);
        setIsTyping(false);
      }
    }, 30);
  }

  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <Switch
        checkedChildren="GPT4.0"
        unCheckedChildren="GPT3.5"
        onChange={(checked) => {
          if (checked) {
            // setGptmodel(model.gpt4);
          } else {
            setGptmodel(model.gpt3);
          }
        }}
      />
      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>
      <a
        href="https://chat.openai.com/g/g-TLO8OQJ2S-japanese-honorific-converter"
        style={{ textDecoration: "underline" }}
      >
        <h2>{text[Language].beta}</h2>
      </a>

      <div className="Container p5">
        <input
          className="input-with-original-styles"
          type="text"
          value={inputvalue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={() => {
            if (!hasTriggeredEvent) {
              handleSend();
              setHasTriggeredEvent(true);
              setTimeout(() => {
                setHasTriggeredEvent(false);
              }, 1000);
              if (show1 === false) {
                setshow1(true);
              }
            }
          }}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <MainContainer>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? <TypingIndicator content="working" /> : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>

            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
