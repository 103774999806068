import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Fast from '../pages/FastLearning';

const FA = () => {
    return (
        <div>
            <Nav />
            <Fast />
            <Footer />
            
        </div>
    )
}

export default FA