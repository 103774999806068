import React from "react";

export const options = [
  <option key="0" value="日程の再調整">
    日程の再調整
  </option>,
  <option key="1" value="明日休みたい">
    明日休みたい
  </option>,
  <option key="2" value="日程の打ち合わせ">
    日程の打ち合わせ
  </option>,
  <option key="3" value="予定のキャンセル">
    予定のキャンセル
  </option>,
  <option key="4" value="注文の依頼">
    注文の依頼
  </option>,
  <option key="5" value="謝罪やお詫びしたい">
    お詫びしたい
  </option>,
  <option key="6" value="催促したい">
    催促したい
  </option>,
  <option key="7" value="本日の業務報告">
    本日の業務報告
  </option>,
  <option key="8" value="出張不在・留守のお知らせ（通知）">
    出張不在・留守のお知らせ（通知）
  </option>,
  <option key="9" value="弊社休日のご連絡">
    弊社休日のご連絡
  </option>,
  <option key="10" value="【自分で他の要件を記入】">
    【自分で他の要件を記入】
  </option>,
];
