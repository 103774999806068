import React from 'react'
import { useState,useMemo,useContext} from "react";
import { Button,Spin } from 'antd';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import CopyButton from '../component/CopyButton';
import OptionJob from '../component/OptionJob';
import OptionFiled from '../component/OptionFiled';
import { useApiKey } from '../component/API';
import { LanguageContext } from "../component/LanguageContext";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";


const Resume = () => {
  const { language: Language } = useContext(LanguageContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [Optionstate1,setOptionstate1] = useState("");
  const [Optionstate2,setOptionstate2] = useState("");
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  //只触发一次
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const [hasTriggeredEvent2, setHasTriggeredEvent2] = useState(false);
  const [hasTriggeredEvent3, setHasTriggeredEvent3] = useState(false);
  const apiKey = useApiKey();

    const text = useMemo(
      () => ({
        EN: {
          title: "A.P Reason",
          message: "Choose your career",
          // input: "Lets take a walk outside",
        },

        JP: {
          title: "志望理由",
          message: "キャリアを選択してください",
          // input: "天気がいいから散歩しましょう",
        },

        CN: {
          title: "理由生成",
          message: "选择你应聘的职业",
          // input: "天气真好我们去散步吧",
        },
      }),
      []
    );

    

  const handleSend = async (message) => {
  let newMessage = {
  message: `${Optionstate1 === "【自分で他の職種を記入】" ? prompt("職業を記入してください") : Optionstate1}${Optionstate2 === "【自分で分野を選んでください】" ? prompt("業界を記入してください") : Optionstate2}会社に応募したい、志望理由かいてください`,
  direction: "outgoing",
  sender: "user",
};
    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };
  //这段代码实现了将用户发送的消息传递给OpenAI的聊天机器人API，获取对应的回复，并将回复添加到聊天记录中。
   async function processMessageToChatGPT(chatMessages) {
  const apiMessages = [];
  for (const messageObject of chatMessages) {
    const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
    apiMessages.push({ role, content: messageObject.message });
  }
  const apiRequestBody = {
    model: "gpt-3.5-turbo-0613",
    messages: [...apiMessages],
  };

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + apiKey,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiRequestBody),
  });

  const data = await response.json();
  const messageText = data.choices[0].message.content;
  const messageChars = messageText.split("");
  let currentIndex = 0;

  const intervalId = setInterval(() => {
    setMessages([
      {
        message: messageText.slice(0, currentIndex + 1),
        sender: "ChatGPT",
      },
    ]);
    currentIndex++;
    if (currentIndex === messageChars.length) {
      clearInterval(intervalId);
      setIsTyping(false);
    }
  }, 30);
  }



  return (
    <div>
      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>
      <div className="Container1">
        <select
          className="Custom-select"
          onChange={(e) => {
            if (!hasTriggeredEvent) {
              const selectOption1 = e.target.value;
              setOptionstate1(selectOption1);
              setShow1(!show1);
              setHasTriggeredEvent(true);
            }
          }}
        >
          {OptionJob}
        </select>
        {show1 && (
          <div>
            <h2>業界を選んでください</h2>
            <select
              className="Custom-select"
              onChange={(e) => {
                if (!hasTriggeredEvent2) {
                  const selectOption2 = e.target.value;
                  setShow2(!show2);
                  setOptionstate2(selectOption2);
                  setHasTriggeredEvent2(true);
                }
              }}
            >
              {OptionFiled}
            </select>
          </div>
        )}
        <div className="Text">
          {show2 && (
            <Button
              type="primary"
              placeholder="明日の休憩を取りたい"
              onClick={() => {
                if (!hasTriggeredEvent3) {
                  handleSend();
                  setShow3(!show3);
                  setHasTriggeredEvent3(true);
                }
              }}
            >
              {isTyping ? <Spin /> : "RUN"}
            </Button>
          )}
          {show3 && (
            <div>
              <MainContainer>
                <ChatContainer>
                  <MessageList
                    scrollBehavior="smooth"
                    typingIndicator={
                      isTyping ? <TypingIndicator content="working" /> : null
                    }
                  >
                    {messages.map((message, i) => {
                      // console.log(message);
                      return <Message key={i} model={message} />;
                    })}
                  </MessageList>
                </ChatContainer>
              </MainContainer>
              {!isTyping && <CopyButton istyping={false} messages={messages} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Resume
