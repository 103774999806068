import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Tutor from '../pages/Tutor';

const TU = () => {
    return (
        <div>
            <Nav />
            <Tutor />
            <Footer />
            
        </div>
    )
}

export default TU