import React, { useState, useEffect, useRef, useContext } from "react";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CaretDownOutlined, GlobalOutlined } from "@ant-design/icons";
import { LanguageContext } from "./LanguageContext";
import "./Nav.css";

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showToolDropdown, setShowToolDropdown] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const dropdownRef = useRef(null);
  const toolDropdownRef = useRef(null);
  const contactRef = useRef(null);
  const { setLanguage } = useContext(LanguageContext);
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (
        toolDropdownRef.current &&
        !toolDropdownRef.current.contains(event.target)
      ) {
        setShowToolDropdown(false);
      }
      if (contactRef.current && !contactRef.current.contains(event.target)) {
        setShowContact(false);
      }
      if (contactRef.current && !contactRef.current.contains(event.target)) {
        setShowLanguage(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef, toolDropdownRef, contactRef]);

  const handleToolClick = () => {
    setShowToolDropdown((prevState) => !prevState);
  };

  const handleJobHuntClick = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleContactClick = () => {
    setShowContact((prevState) => !prevState);
  };

  const handleLClick = () => {
    setShowLanguage((prevState) => !prevState);
  };

  return (
    <nav className="nav">
      <Link to="" className="brand">
        Gpt-Work
      </Link>

      <ul className="nav__menu">
        <li className="nav__item">
          <Link to="/Keigo">Gpt-Keigo</Link>
        </li>
        <li className="nav__item" onClick={handleToolClick}>
          <Link to="">
            Tool
            <CaretDownOutlined />
          </Link>
          {showToolDropdown && (
            <div className="dropdown" ref={toolDropdownRef}>
              <ul>
                <li>
                  <Link to="/Mail">Gpt-Mail</Link>
                </li>
                <li>
                  <Link to="/Translate">Gpt-Translate</Link>
                </li>
                <li>
                  <Link to="/Decision">Gpt-Decision</Link>
                </li>
                <li>
                  <Link to="/Involve">Gpt-Involve</Link>
                </li>
                <li>
                  <Link to="/LP">Gpt-LearnNewSkill</Link>
                </li>
                <li>
                  <Link to="/FastLearning">Gpt-FastLearning</Link>
                </li>
                <li>
                  <Link to="/LearnFromBest">Gpt-LearnFromBest</Link>
                </li>
                <li>
                  <Link to="/LearnTutor">Gpt-LearningTutor</Link>
                </li>
              </ul>
            </div>
          )}
        </li>
        <li className="nav__item" onClick={handleJobHuntClick}>
          <Link to="">
            JobHunt
            <CaretDownOutlined />
          </Link>
          {showDropdown && (
            <div className="dropdown" ref={dropdownRef}>
              <ul>
                <li>
                  <Link to="/introduction">Howtouse</Link>
                </li>
                <li>
                  <Link to="/GptSelfcheck">Gpt-Selfcheck</Link>
                </li>
                <li>
                  <Link to="/GptResume">Gpt-Resume</Link>
                </li>
                <li>
                  <Link to="/GptCompany">Gpt-Company</Link>
                </li>
                <li>
                  <Link to="/GptInterview">Gpt-Interview</Link>
                </li>
                <li>
                  <Link to="/Gptshiboriyu">Gpt-shiboriyu</Link>
                </li>
              </ul>
            </div>
          )}
        </li>

        <li className="nav__item" onClick={handleContactClick}>
          <Link to="">
            Contact
            <FaDiscord />
          </Link>
          {showContact && (
            <div className="dropdown" ref={contactRef}>
              <ul>
                <li>
                  <a href="https://discord.gg/ud6RpY39nG">Discord</a>
                </li>
                <li>
                  <Link to="/Advise">More</Link>
                </li>
                <li>
                  <Link to="/Coffee">Coffee</Link>
                </li>
              </ul>
            </div>
          )}
        </li>

        <li className="nav__item" onClick={handleLClick}>
          <Link to="">
            Language
            <GlobalOutlined />
          </Link>
          {showLanguage && (
            <div className="dropdown" ref={contactRef}>
              <ul>
                <li onClick={() => handleLanguageChange("CN")}>
                  <Link to="#">Chinese</Link>
                </li>
                <li onClick={() => handleLanguageChange("EN")}>
                  <Link to="#">English</Link>
                </li>
                <li onClick={() => handleLanguageChange("JP")}>
                  <Link to="#">Japanese</Link>
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Header;
