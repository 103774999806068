import React, { useState } from "react";
import { Button } from "antd";

const CopyButton = ({ istyping, messages }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const copyText = messages.map(message => message.message).join('\n')
    navigator.clipboard.writeText(copyText);
    setIsCopied(true);
  };

  return (
    <div>
      {!istyping && (
        <Button type="primary" onClick={handleCopy}>
          {isCopied ? "COPIED" : "COPY"}
        </Button>
      )}
    </div>
  );
};

export default CopyButton;
